//合伙人下载、商户下载、合伙人注册页自定义微信分享
import { getAuthInfo } from "@/api/common";
import wx from "weixin-js-sdk";
import { Toast } from "vant";

const shareConfig = (title, desc, imgUrl) => {
  console.log("调用了", title, desc, imgUrl);
  let ua = window.navigator.userAgent.toLowerCase();
  let isWeiXin = ua.match(/MicroMessenger/i) == "micromessenger";
  // console.log("$isWeiXin", isWeiXin);
  if (isWeiXin) {
    getAuthInfo({ urlType: 1 }).then((res) => {
      console.log("签名信息res", res);
      wx.config({
        debug: false,
        appId: res.appId,
        timestamp: res.timestamp,
        nonceStr: res.nonceStr,
        signature: res.signature,
        jsApiList: [
          "onMenuShareTimeline", //分享到朋友圈
          "onMenuShareAppMessage", //分享给朋友
        ],
      });

      let link = window.location.href;
      let shareData = {
        title: title,
        desc: desc,
        link: link,
        imgUrl: imgUrl,
      };
      wx.ready(() => {
        wx.onMenuShareTimeline(shareData);
        wx.onMenuShareAppMessage(shareData);
      });
    });
  } else {
    Toast({
      // message: "请在微信中打开,否则无法正常使用",
    });
  }
};
export { shareConfig };
